import React from 'react'
import { graphql } from 'gatsby'
import LinkPostItem from '../components/LinkPostItem'
import TitlePage from '../components/TitlePage'
import SEO from '../components/seo'
import Pagination from '../components/Pagination'
import * as S from '../components/ListWrapper/styled'
import BackToAllTopicsButton from '../components/BackToAllTopicsButton'
import SocialShare from '../components/SocialShare'
import theme from '../theme'

const Blog = (props) => {

    console.log('props', JSON.stringify(props))
    console.debug('query', JSON.stringify(query))

    const newsSection = props.data.newsSection.data
    const postList = props.data.postList.edges

    // Logic for Pagination Component
    const { currentPage, numPages } = props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
        currentPage - 1 === 1 ? '/section' : `/section/${currentPage - 1}`
    const nextPage = `/section/page/${currentPage + 1}`
    const pageTitle = newsSection.title
    const subline = newsSection.description
    const hello = newsSection.title

    const centerTextStyle = { textAlign: 'center' }
    const centerItemsStyle = {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    }

    return (
        <>
            <SEO title={pageTitle} description={subline} image={newsSection.image_url}/>
            <TitlePage text={hello}/>

            <br/>

            <div style={centerItemsStyle}>
                <BackToAllTopicsButton/>
            </div>
            <br/>

            <SocialShare pageTitle={pageTitle} subline={subline} url={props.location.href}/>

            <br/>

            <S.ListWrapper>
                {postList.map(
                    (post, i) => (
                        <LinkPostItem
                            backgroundColor={theme.colors.primary}
                            section={props.pageContext.sectionName}
                            date={post.node.data.date}
                            title={post.node.data.title}
                            description={post.node.data.description}
                            imageURL={post.node.data.image_url}
                            url={post.node.data.url}
                            hashTags={post.node.data.hash_tags}
                            readingLevel={post.node.data.reading_level}
                        />
                    ),
                )}
            </S.ListWrapper>

            <Pagination
                isFirst={isFirst}
                isLast={isLast}
                currentPage={currentPage}
                numPages={numPages}
                prevPage={prevPage}
                nextPage={nextPage}
            />
        </>
    )
}

// https://momentjs.com/docs/#/parsing/string-format/
export const query = graphql`
    query LinkPosts($sectionName: String!) {
        postList: allAirtable(filter: {table: {eq: "LinkPost"}, data: {sectionName: {eq: $sectionName}}}, sort: {order: DESC, fields: data___date}) {
            edges {
                node {
                    data {
                        date(formatString: "YYYY-MM-DD")
                        description
                        hash_tags
                        id
                        image_url
                        sectionName
                        url
                        title
                        reading_level
                    }
                }
            }
        }
        newsSection: airtable(data: {name: {eq: $sectionName}}) {
            data {
                title
                description
                image_url
            }
        }
    }
`

export default Blog
