import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const LinkPostItem = ({
                          backgroundColor,
                          section,
                          date,
                          title,
                          description,
                          imageURL,
                          url,
                          hashTags,
                          readingLevel,
                      }) => {

    const anchorStyle = {
        textDecoration: 'none',
        display: 'block',
        marginBottom: 'var(--space)',
    }

    return (
        <a style={anchorStyle} href={url} target={'_self'}>
            <S.PostItemWrapper>
                {imageURL && (
                    <S.PostItemExtImg
                        src={imageURL}
                        alt={title}
                    />
                )}
                {!imageURL && (
                    <S.PostItemExtImg
                        alt={title}
                    />
                )}

                <S.PostItemInfo>
                    <S.PostItemTag backgroundColor={backgroundColor}>
                        {section}
                    </S.PostItemTag>
                    <S.PostItemTitle>{title}</S.PostItemTitle>
                    <S.PostItemDescription>{description}</S.PostItemDescription>
                    <S.PostItemDescription>READING LEVEL: {readingLevel}</S.PostItemDescription>
                    <S.PostItemDate>LAST ACCESSED: {date}</S.PostItemDate>
                </S.PostItemInfo>
            </S.PostItemWrapper>
        </a>
    )
}

LinkPostItem.propTypes = {
    backgroundColor: PropTypes.string,
    section: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string,
    hashTags: PropTypes.string,
    imageURL: PropTypes.string,
    readingLevel: PropTypes.string,
}

export default LinkPostItem
